var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.workOrders.data),function(workOrder){return _c('div',{key:workOrder.uuid,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"is-block",attrs:{"to":{
                                    name: 'work-order-manager',
                                    params: {
                                        workOrder: workOrder.uuid
                                    }
                                }}},[_vm._v(" #"+_vm._s(workOrder.number)+" "+_vm._s(workOrder.reference)+" "),(_vm.jobIsOverdue(workOrder))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`This work order is passed due.`),expression:"`This work order is passed due.`"}],staticClass:"has-text-danger"},[_c('icon',{attrs:{"icon":"stopwatch"}})],1):_vm._e()]),_c('small',{staticClass:"has-text-grey"},[_vm._v("For "+_vm._s(workOrder.customer.name)+" at "+_vm._s(workOrder.location.name)+", Updated "+_vm._s(_vm._f("asCalendar")(workOrder.updated_at)))])],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('The team assigned to this Work Order'),expression:"'The team assigned to this Work Order'"}]},[_c('icon',{staticClass:"has-text-grey mr-1",attrs:{"icon":"people-arrows"}}),_c('span',[_vm._v(_vm._s(workOrder.team.name))])],1)]),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('circular-progress-bar',{attrs:{"tooltip":"Current progress of the Work Order","total":workOrder.inspections.length,"total-complete":workOrder.inspections.map(inspection => inspection.closed_off_at).length,"backgroundColor":"#212127"}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-center"},[_c('priority-indicator',{attrs:{"priority":workOrder.priority}})],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('outcome-tag',{attrs:{"status":workOrder.status}})],1)],1)],1)}),0)])],1),(_vm.workOrders.data.length)?_c('pager',{attrs:{"pageable":_vm.workOrders,"context":"Work Order","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.workOrders.data.length,"with-link":"","heading":"There are no work orders available for display.","to":{
                name: 'create-work-order'
            },"to-text":"New Work Order"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }